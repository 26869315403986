<template>
  <nav class="navbar">
    <div class="container">
      <a class="navbar-brand" href="#">
        <h1 class="fw-bold">Enclave Executive Command</h1>
      </a>
      <div class="navbar-icons">
        <a href="https://discord.gg/gQgtrRKefh" target="_blank" rel="noopener noreferrer" class="discord-button" @click="trackEvent('Join us on Discord')">
          <font-awesome-icon :icon="['fab', 'discord']" /> Join us on Discord
        </a>
        <button class="donate-button" @click="openDonationLink">
          Buy War Bonds
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavbar',
  methods: {
    trackEvent(action) {
      if (window.gtag) {
        window.gtag('event', action, {
          'event_category': 'Button',
          'event_label': 'Navbar'
        });
      }
    },
    openDonationLink() {
      this.trackEvent('Donate');
      window.open('https://www.paypal.com/donate/?hosted_button_id=83CQW8EV4H848', '_blank');
    }
  }
}
</script>

<style scoped>
.navbar {
  background-color: rgba(46, 87, 140, 1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 15px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.navbar-brand {
  font-size: 24px;
  font-weight: 700;
  color: #f6f6f6;
}

.navbar-brand:hover {
  font-size: 24px;
  font-weight: 700;
  color: #F2E641;
}

.navbar-icons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.discord-button {
  background-color: #5865F2;
  color: white !important;
  padding: 3px 20px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.discord-button:hover {
  background-color: #4752C4;
}

.donate-button {
  background-color: #ffc439; /* PayPal's officielle gule farve */
  color: #333; /* PayPal's mørkeblå farve for tekst */
  padding: 3px 20px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donate-button:hover {
  background-color: #f3ba2f; /* En lidt mørkere nuance af gul for hover-effekten */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-brand {
    margin-bottom: 10px;
    text-align: center;
  }

  .navbar-icons {
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .navbar-icons::-webkit-scrollbar {
    display: none;
  }

  .discord-button, .donate-button {
    white-space: nowrap;
  }
}
</style>
